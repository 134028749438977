import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import ProductPresentation from "@/components/ProductPresentation";
import ProductAdvantages from "@/components/ProductAdvantages";
import AboutProduct from "@/components/AboutProduct";
import Typography from "@/components/Typography";
import InvestTime from "@/containers/InvestTime";
import Button from "@/components/Button/Button";
export const _frontmatter = {
  "title": "Regularização de CPF/CPF Bloqueado",
  "path": "/cpf",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation contactLink="https://wa.me/5581994019577" knowMoreLink="https://www.instagram.com/leancontabilidade" description="Nosso time de especialistas está pronto para lhe atender." title={() => <>
      Caiu na malha fina? Entre em contato e receba um orçamento para
      regularizar sua situação.
    </>} mdxType="ProductPresentation" />
    <InvestTime mdxType="InvestTime" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      